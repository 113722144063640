<template>
  <div class="weixin">
    <div class="page" v-if="status == '1'">
      <van-icon name="checked" class="success-color" size="80" />
      <p>授权成功</p>
    </div>
    <div class="page" v-if="status == '2'">
      <van-icon name="clear" class="failure-color" size="80" />
      <p>授权失败</p>
    </div>
  </div>
</template>

<script>
import { geyWeixinAuto } from "@/api/weixinAuto"
export default {
  data() {
    return {
      code: "",
      status: ""
    }
  },
  created() {
    this.code = this.getUrlParams().code;
    this.geyWeixinAuto();
  },
  methods: {
    // 【转换】获取路径"?"后面的参数
    getUrlParams() {
      let path = location.search;
      let params = new Object();
      if (path.indexOf("?") != -1) {
        path = path.slice(1).split("&");
        for (let i of path) {
          params[i.split("=")[0]] = decodeURI(i.split("=")[1]);
        }
      }
      return params;
    },

    // 【请求】添加
    geyWeixinAuto() {
      let data = {
        code: this.code,
      };
      geyWeixinAuto(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.status = '1'
        } else {
          this.status = '2'
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.weixin {
  height: 100%;
}

.page {
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 16px;
    padding: 0.2rem 0;
  }
}

.success-color {
  color: #07c160;
}

.failure-color {
  color: red;
}
</style>